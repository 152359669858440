var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "container px-8 mx-auto h-4/5" }, [
    _c(
      "h1",
      { staticClass: "mt-6 mb-6" },
      [
        _c(
          "router-link",
          { attrs: { to: { name: "package.collection" } } },
          [
            _c("font-awesome-icon", {
              staticClass: "mx-2",
              attrs: { icon: ["fa", "arrow-left"] }
            }),
            _c(
              "span",
              {
                staticClass: "my-6 text-2xl font-bold",
                attrs: { "data-cy": "back-button" }
              },
              [_vm._v("All Packages")]
            )
          ],
          1
        )
      ],
      1
    ),
    _c("div", { staticClass: "flex-row justify-center w-full h-full" }, [
      _c("div", { staticClass: "w-full mb-10 bg-white border border-black" }, [
        _c(
          "div",
          { staticClass: "flex w-full pt-8 pb-20 pl-12 bg-white" },
          [
            _c("package-icon", {
              staticClass: "w-20 h-20 text-4xl",
              attrs: {
                backgroundColor: _vm.pack.iconColor,
                icon: _vm.pack.icon,
                packageFamily: _vm.pack.family,
                "data-cy": "package-icon-component"
              }
            }),
            _c("div", { staticClass: "flex-row w-1/2" }, [
              _c(
                "p",
                {
                  staticClass: "pl-12 text-4xl skyhub-text",
                  attrs: { id: "table-title" }
                },
                [_vm._v(" " + _vm._s(_vm.pack.name) + " ")]
              ),
              _c(
                "p",
                { staticClass: "pl-12 text-lg break-words skyhub-subtext" },
                [_vm._v(" " + _vm._s(_vm.pack.description) + " ")]
              )
            ]),
            _c(
              "div",
              { staticClass: "flex justify-end flex-grow pr-8" },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "package.detail.edit" } } },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "py-1 pr-2 align-middle bg-white border-2 border-gray-300 rounded",
                        attrs: { "data-cy": "edit-package-button" }
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "mx-2",
                          attrs: { icon: ["fa", "edit"] }
                        }),
                        _c("span", [_vm._v("Edit")])
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "flex px-8 pt-4 border border-black bg-gray-50" },
        [
          _c(
            "h2",
            {
              staticClass:
                "inline px-3 pt-2 font-bold border-b-4 border-green-500"
            },
            [_vm._v(" Versions ")]
          ),
          _c(
            "div",
            { staticClass: "flex flex-col items-end w-full pb-4" },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "package.detail.version.create" } } },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "p-1 text-base text-gray-500 align-middle bg-white border-2 border-gray-300 rounded",
                      attrs: { "data-cy": "create-version-button" }
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: ["fa", "plus"] }
                      }),
                      _c("span", [_vm._v("Create new version")])
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "w-full bg-white border-b border-l border-r border-black h-3/6"
        },
        [
          _c("div", { staticClass: "h-full overflow-y-scroll" }, [
            _c(
              "table",
              {
                staticClass: "w-full table-fixed skyhub-table",
                attrs: { "aria-describedby": "table-title" }
              },
              [
                _c("thead", [
                  _c("tr", { staticClass: "text-left border-b" }, [
                    _c(
                      "th",
                      {
                        staticClass: "sticky top-0 w-2/12 px-8 py-2 bg-gray-50",
                        attrs: { scope: "col" }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "cursor-pointer",
                            on: {
                              click: function($event) {
                                return _vm.sortByVersion()
                              }
                            }
                          },
                          [
                            _vm._v(" Version "),
                            _c("table-sort-arrow-component", {
                              attrs: { sorting: _vm.sorting, name: "version" }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "sticky top-0 w-1/12 px-8 py-2 bg-gray-50",
                        attrs: { scope: "col" }
                      },
                      [_vm._v(" Tags ")]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "sticky top-0 w-2/12 px-8 py-2 bg-gray-50",
                        attrs: { scope: "col" }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "cursor-pointer",
                            on: {
                              click: function($event) {
                                return _vm.sortByCreateDate()
                              }
                            }
                          },
                          [
                            _vm._v(" Published "),
                            _c("table-sort-arrow-component", {
                              attrs: {
                                sorting: _vm.sorting,
                                name: "createdDate"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "sticky top-0 w-2/12 px-8 py-2 bg-gray-50",
                        attrs: { scope: "col" }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "cursor-pointer",
                            on: {
                              click: function($event) {
                                return _vm.sortByPlatform()
                              }
                            }
                          },
                          [
                            _vm._v(" Platform "),
                            _c("table-sort-arrow-component", {
                              attrs: { sorting: _vm.sorting, name: "platform" }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "sticky top-0 w-2/12 px-8 py-2 bg-gray-50",
                        attrs: { scope: "col" }
                      },
                      [_vm._v(" MD5 Hash ")]
                    ),
                    _c("th", {
                      staticClass: "sticky top-0 w-2/12 px-8 py-2 bg-gray-50",
                      attrs: { scope: "col" }
                    }),
                    _c("th", {
                      staticClass:
                        "sticky top-0 z-50 w-1/12 px-8 py-2 bg-gray-50",
                      attrs: { scope: "col" }
                    })
                  ])
                ]),
                _c(
                  "tbody",
                  _vm._l(this.pack.versions, function(version, index) {
                    return _c(
                      "tr",
                      { key: index, staticClass: "skyhub-table-row" },
                      [
                        _c("td", { staticClass: "px-8 py-4" }, [
                          _c("div", [_vm._v(_vm._s(version.version))])
                        ]),
                        _c("td", { staticClass: "px-8 py-4" }, [
                          _vm.latestVersionId == version.id
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center w-16 p-2 leading-none text-purple-700 bg-purple-300 rounded"
                                },
                                [_vm._v(" Latest ")]
                              )
                            : _vm._e()
                        ]),
                        _c(
                          "td",
                          { staticClass: "px-8 py-4" },
                          [
                            _c("time-component", {
                              attrs: { date: version.createdDate }
                            })
                          ],
                          1
                        ),
                        _c("td", { staticClass: "px-8 py-4" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.packagePlatformLabel.get(version.platform)
                              ) +
                              " "
                          )
                        ]),
                        _c(
                          "td",
                          {
                            staticClass:
                              "px-8 py-4 w-0.5 break-words overflow-hidden overflow-ellipsis",
                            style: { "white-space": _vm.md5WhiteSpaceStyle },
                            on: {
                              click: function($event) {
                                return _vm.md5TruncateToggle()
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(version.md5) + " ")]
                        ),
                        _c("td", { staticClass: "py-4 text-center" }, [
                          _c(
                            "button",
                            {
                              staticClass: "flex px-4 pt-1 mt-2",
                              on: {
                                click: function($event) {
                                  return _vm.downloadFile(index)
                                }
                              }
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "mt-1 mr-3 border-b border-black",
                                attrs: {
                                  icon: ["fa", "arrow-down"],
                                  "data-cy": "download-version-file"
                                }
                              }),
                              _c("span", { staticClass: "pb-4" }, [
                                _vm._v("Download")
                              ])
                            ],
                            1
                          )
                        ]),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _vm.latestVersionId != version.id
                              ? _c("drop-down", [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "inline-flex items-center w-full px-4 py-2 text-sm leading-5 skyhub-table-row",
                                      on: {
                                        click: function($event) {
                                          return _vm.setVersionLatest(
                                            version.id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "mr-8 fa-lg",
                                        attrs: { icon: ["fas", "angle-up"] }
                                      }),
                                      _vm._v(" Make Latest ")
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  }),
                  0
                )
              ]
            )
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }