






















































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IPackage } from '@/models/packages/Package';
import Config from '@/config';
import { PackagePlatformLabels } from '@/models/packages/PackagePlatformType';
import PackageIcon from '@/components/PackageIconComponent.vue';
import DropDown from '@/components/DropDownComponent.vue';
import TableSortArrowComponent from '@/components/TableSortArrowComponent.vue';
import TimeComponent from '@/components/TimeComponent.vue';
import { Sorter } from '@/util/Sorter';

@Component({
  components: {
    PackageIcon,
    DropDown,
    TableSortArrowComponent,
    TimeComponent
  }
})
export default class PackageDetailPage extends Vue {
  private md5WhiteSpaceStyle = 'nowrap';
  private apiBaseUrl = '';
  private edited = false;
  private packagePlatformLabel = PackagePlatformLabels;
  private activeDropdownIndex = -1;
  private latestVersionId = -1;
  private versionTabActive = true;
  private nodesTabActive = false;

  public sorting: Sorter = new Sorter();

  @Prop({ default: () => [] })
  private pack!: IPackage;

  async downloadFile(versionIndex: number) {
    const packageId = this.pack.id;
    const versionId = this.pack.versions[versionIndex].id;
    const response = await this.$store.dispatch(
      'packageModule/fetchPresignedDownloadUrl',
      { packageId, versionId }
    );

    window.location.href = response.url;
  }

  created() {
    this.apiBaseUrl = Config.skynode.skynodeApi;
    if (this.pack && this.pack.latestVersion != null) {
      this.latestVersionId = this.pack.latestVersion.id;
    }
  }

  private async setVersionLatest(versionId: string) {
    await this.$store.dispatch('packageModule/updatePackageVersion', {
      packageId: this.pack.id,
      versionId: versionId,
      isLatest: true
    });
    this.latestVersionId = parseInt(versionId);
  }

  private sortByVersion() {
    this.sorting.sortVersions((p) => p.version, this.pack.versions, 'version');
  }

  private sortByCreateDate() {
    this.sorting.sort((p) => p.createdDate, this.pack.versions, 'createdDate');
  }

  private sortByPlatform() {
    this.sorting.sort(
      (p) => this.packagePlatformLabel.get(p.platform),
      this.pack.versions,
      'platform'
    );
  }

  private md5TruncateToggle() {
    if (this.md5WhiteSpaceStyle == 'nowrap') {
      this.md5WhiteSpaceStyle = 'normal';
    } else {
      this.md5WhiteSpaceStyle = 'nowrap';
    }
  }
}
